@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

html {
    @apply bg-primary-dark text-primary-light;
    scroll-behavior: smooth;
}
